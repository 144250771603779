import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import {
  getMetricSystem,
  getFullDateFormat,
} from 'utils/multilanguage-support';
import { getToken } from 'utils/getToken';
import { getLanguage } from 'utils/getLanguage';
import { PRODUCT_TYPE } from '../../global-constants';

const prepareHeaders = (headers: Headers) => {
  headers.set('Accept-Language', getLanguage());
  headers.set('X-Product-Type', PRODUCT_TYPE);
  const idToken = getToken();
  if (idToken) {
    headers.set('Authorization', `Bearer ${idToken}`);
    headers.set('IsMetricSystem', getMetricSystem().toString());
    headers.set('DateNotation', getFullDateFormat());
  }
  return headers;
};

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  prepareHeaders,
});

const maxRetries = process.env.NODE_ENV === 'test' ? 0 : 4;
const baseQueryWithRetry = retry(baseQuery, { maxRetries });

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const api = createApi({
  baseQuery: baseQueryWithRetry,
  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
  endpoints: () => ({}),
});
