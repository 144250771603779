import { AlertBox, ContentfulRenderer } from 'components';
import translate from 'translate';
import { ILoginErrorMessage } from 'interfaces/ILoginErrorMessage';
import clsx from 'clsx';
import styles from './styles.module.scss';

interface ILoginErrorAlertProps {
  loginErrorData?: ILoginErrorMessage;
}

export const LoginErrorAlert = ({ loginErrorData }: ILoginErrorAlertProps) => (
  <AlertBox type="error">
    <div id="form_helper_text">
      <div
        className={clsx(
          styles.bold,
          loginErrorData?.errorMessage && styles.spaceUnder
        )}
      >
        {translate('Login_ErrorMessage')}
      </div>
      {loginErrorData?.errorMessage ? (
        <div className={styles.customErrorWrapper}>
          <ContentfulRenderer>{loginErrorData.errorMessage}</ContentfulRenderer>
        </div>
      ) : null}
    </div>
  </AlertBox>
);
