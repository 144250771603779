import React, { useState } from 'react';
import { BodyText } from 'components/UI/Brand/text';
import { IFaq } from 'interfaces/IFaq';
import ContentfulRenderer from 'components/ContentfulRenderer';
import Icon from 'components/UI/Icons/Icon';
import styled from 'styled-components';
import { colors } from 'style/colors';

interface IAccordionItemProps {
  article: IFaq;
  isOpen?: boolean;
}

const AccordionDetails = styled.details`
  background-color: ${colors.shared.white};
  border: 1px solid ${colors.shared.warmGray2};
  &:hover {
    border-color: ${colors.hrx.blue};
  }
`;

const AccordionSummary = styled.summary`
  list-style: none;
  padding: 1.5rem;
  cursor: pointer;

  &::-webkit-details-marker {
    display: none;
  }
`;

const Container = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
`;

const AccordionPanel = styled.section`
  padding: 0 1.5rem 1.5rem 1.5rem;
`;

const AccordionItem: React.FC<IAccordionItemProps> = ({
  article,
  isOpen = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(isOpen);

  return (
    <AccordionDetails>
      <AccordionSummary onClick={() => setIsExpanded(!isExpanded)}>
        <Container>
          <BodyText as="h4">{article.question}</BodyText>
          <Icon
            iconType={isExpanded ? 'minus' : 'plus'}
            color={colors.hrx.blue}
            width={24}
            height={24}
          />
        </Container>
      </AccordionSummary>
      <AccordionPanel>
        {article.answer && (
          <ContentfulRenderer>{article.answer}</ContentfulRenderer>
        )}
      </AccordionPanel>
    </AccordionDetails>
  );
};

export default AccordionItem;
