import clsx from 'clsx';
import { SecondaryButton } from 'components/UI/Buttons/SecondaryButton/SecondaryButton';
import { BodyText } from 'components/UI/Brand/text';
import { useEffect, useState } from 'react';
import { DEFAULT_SUPPORT_EMAIL, getSupportEmail } from 'utils/supportInfo';
import Icon from 'components/UI/Icons/Icon';
import translate from 'translate';
import { LinkText } from 'components';
import { colors } from 'style/colors';
import styles from './styles.module.scss';

interface INoBookingsProps {
  isFullHeight?: boolean;
  isInProgress?: boolean;
  startToExplore: () => void;
}

const NoBookings = ({
  startToExplore,
  isFullHeight = false,
  isInProgress = false,
}: INoBookingsProps) => {
  const [supportEmail, setSupportEmail] = useState<string>(
    DEFAULT_SUPPORT_EMAIL
  );

  useEffect(() => {
    getSupportEmail().then(setSupportEmail);
  }, []);

  return (
    <div
      className={clsx(styles.noResultRoot, {
        [styles.fullHeightRoot]: isFullHeight,
      })}
    >
      <Icon
        iconType="binocular"
        width={53}
        height={53}
        color={colors.shared.warmGray3}
        className={styles.icon}
      />
      {isInProgress && (
        <span className={styles.description}>
          {translate(
            'MyBookingsList_NoActiveBookings_HX',
            <LinkText href={`mailto:${supportEmail}`}>{supportEmail}</LinkText>
          )}
        </span>
      )}
      {!isInProgress && (
        <BodyText className={styles.description}>
          {translate('Home_NoActiveBookings')}
        </BodyText>
      )}
      <div className={styles.exploreButton}>
        <SecondaryButton
          className={styles.button}
          onClick={() => {
            startToExplore();
          }}
        >
          {translate('MyBookingsList_ExploreButton')}
        </SecondaryButton>
      </div>
    </div>
  );
};

export default NoBookings;
