import { createActions, ActionFunctionAny, Action } from 'redux-actions';
import axios from 'axios-instance';
import { PRODUCT_TYPE } from '../../global-constants';

export const {
  fetchOnboardingPopupStart,
  fetchOnboardingPopupSuccess,
  fetchOnboardingPopupFailed,
} = createActions({
  FETCH_ONBOARDING_POPUP_START: undefined,
  FETCH_ONBOARDING_POPUP_SUCCESS: (infoPopup) => ({ infoPopup }),
  FETCH_ONBOARDING_POPUP_FAILED: undefined,
});

export const fetchInfoPopup =
  () => (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(fetchOnboardingPopupStart());

    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}home/onboarding`, {
        params: PRODUCT_TYPE,
      })
      .then((response) => {
        dispatch(fetchOnboardingPopupSuccess(response.data));
      })
      .catch(() => {
        dispatch(fetchOnboardingPopupFailed());
      });
  };
