import { Action, ActionFunctionAny, createActions } from 'redux-actions';
import axios from 'axios';
import { IAgent } from 'interfaces/IOnboarding';
import { PRODUCT_TYPE } from '../../global-constants';

export const {
  leadCreated,
  registrationStart,
  registrationSuccess,
  registrationFailed,
  registrationClear,
} = createActions({
  LEAD_CREATED: undefined,
  REGISTRATION_START: undefined,
  REGISTRATION_SUCCESS: (registrationResponse) => ({ registrationResponse }),
  REGISTRATION_FAILED: (error) => ({ error }),
  REGISTRATION_CLEAR: undefined,
});

export const registration =
  (registrationData: IAgent, migrate = false, publicIp = '') =>
  (dispatch: ActionFunctionAny<Action<void>>) => {
    const url = migrate
      ? `${process.env.REACT_APP_API_BASE_URL}User/migrate`
      : `${process.env.REACT_APP_API_BASE_URL}User`;

    dispatch(registrationStart());
    axios
      .post(url, registrationData, {
        headers: {
          'x-fallback-ip': publicIp,
        },
        params: {
          productType: PRODUCT_TYPE,
        },
      })
      .then((response) => {
        if (response.headers?.location === 'Lead created') {
          dispatch(leadCreated());
        }
        dispatch(registrationSuccess(response.data));
      })
      .catch((error) => {
        dispatch(registrationFailed(error.response.data));
        const errorToLog = { ...error };
        if (errorToLog && errorToLog.config && errorToLog.config.data) {
          const errorData = { ...registrationData };
          delete errorData.password;
          errorToLog.config.data = errorData;
        }
      });
  };
