import { Header4, InfoText } from 'components/UI/Brand/text';
import { Link } from 'react-router-dom';
import Icon from 'components/UI/Icons/Icon';
import { colors } from 'style/colors';
import clsx from 'clsx';
import styles from './styles.module.scss';

export interface IArrowCard {
  description?: string;
  linkPath: string;
  title: string;
}

const ArrowCard = ({ linkPath, title, description }: IArrowCard) => (
  <article className={clsx(styles.article)} data-testid="arrow-card-container">
    <Link className={styles.link} to={linkPath}>
      <Header4 as="span" className={styles.title}>
        {title}
      </Header4>
      <Icon
        iconType="arrowRightShort"
        color={colors.hrx.blue}
        width={32}
        height={32}
      />
      {description && (
        <InfoText
          className={styles.description}
          data-testid="arrow-card-description"
        >
          {description}
        </InfoText>
      )}
    </Link>
  </article>
);

export default ArrowCard;
