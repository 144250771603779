import { IAuthPayload, ITokenData } from 'interfaces/IAuth';
import { api } from './api';
import { PRODUCT_TYPE } from '../../global-constants';

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    auth: build.mutation<
      ITokenData,
      { authData: IAuthPayload; productType?: string }
    >({
      query: ({ authData }) => ({
        url: `token`,
        method: 'POST',
        body: authData,
        params: { productType: PRODUCT_TYPE },
      }),
      extraOptions: { maxRetries: 0 },
    }),
  }),
});

export const { useAuthMutation } = authApi;

export const {
  endpoints: { auth },
} = authApi;
