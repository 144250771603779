import { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { SecondaryButton } from 'components/UI/Buttons/SecondaryButton/SecondaryButton';
import { Header3, InfoText } from 'components/UI/Brand/text';
import { IRootState } from 'store/types/root';
import { IOnBoardingPopupPages } from 'interfaces/IOnboardingPopup';
import translate from 'translate';
import clsx from 'clsx';
import { PrimaryButton } from '../Buttons/PrimaryButton/PrimaryButton';
import styles from './styles.module.scss';

const OnboardinPopupCarousel = ({ hideModal }: { hideModal: () => void }) => {
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const { profileData } = useSelector((state: IRootState) => state.profile);
  const { pages } = useSelector(
    (state: IRootState) => state.onboardingPopup.infoPopup
  );
  const { length } = pages;

  const nextSlide = () => {
    setCurrentSlide(currentSlide === length - 1 ? 0 : currentSlide + 1);
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? length - 1 : currentSlide - 1);
  };

  return (
    <>
      <ul aria-live="polite" className={styles.slides} role="group">
        {pages?.map(
          ({ title, image, description }: IOnBoardingPopupPages, index) => (
            <li
              key={`lightOnBoarding-${index + 1}`}
              aria-roledescription="slide"
              aria-current={currentSlide === index}
              aria-label={translate(
                'Carousel_SlideIndexOfLength',
                index,
                length
              )}
              className={styles.slide}
            >
              <Header3 as="h1">
                {currentSlide === 0 && profileData?.firstName
                  ? `${title} ${profileData?.firstName}`
                  : title}
              </Header3>
              <div className={styles.imageContainer}>
                <img
                  className={styles.image}
                  src={image}
                  alt=""
                  role="presentation"
                />
              </div>
              <InfoText>{description}</InfoText>
            </li>
          )
        )}
      </ul>
      <div className={styles.carouselNavigator}>
        <div className={styles.dotContainer} aria-hidden="true">
          {pages?.map(({ title }, index) => (
            <span
              key={`dot-${title}-${index + 1}`}
              aria-current={currentSlide === index}
              className={clsx(styles.dot)}
            />
          ))}
        </div>
        <fieldset
          className={styles.buttonContainer}
          aria-label={translate('Carousel_Buttons')}
          aria-controls="carousel"
        >
          {currentSlide !== 0 && (
            <SecondaryButton onClick={prevSlide}>
              {translate('General_Previous')}
            </SecondaryButton>
          )}
          <PrimaryButton
            onClick={currentSlide === length - 1 ? hideModal : nextSlide}
          >
            {currentSlide === length - 1
              ? translate('General_GotIt')
              : translate('General_Next')}
          </PrimaryButton>
        </fieldset>
      </div>
    </>
  );
};

export default memo(OnboardinPopupCarousel);
