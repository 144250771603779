import { NavLink } from 'react-router-dom';
import { getRegionByLocale, Region } from 'utils/regions';
import { ButtonText } from 'components/UI/Brand/text';
import clsx from 'clsx';
import { getJwtPayload, track } from 'utils/analytics';
import translate from 'translate';
import { getLanguage } from 'utils/getLanguage';
import styles from './styles.module.scss';

const trackBook = () => track('Click book');
const trackLearn = () => track('Click learn');
// const trackGetCertified = () => track('Click get certified');
const trackResources = () => track('Click resources');
const trackAgencyArea = () => track('Click Agenturbereic German');

interface INavItemsLeftProps {
  setLearnButtonActive: (value: boolean) => void;
  setShowLearnNavigation: (value: boolean) => void;
}

const NavItemsLeft = ({
  setShowLearnNavigation,
  setLearnButtonActive,
}: INavItemsLeftProps) => {
  const marketId = getJwtPayload()['http://user/market'];

  function canRegionShowTraining() {
    const region = getRegionByLocale(getLanguage());

    return (
      region === Region.UK ||
      region === Region.Americas ||
      region === Region.EMEA ||
      region === Region.APACs ||
      region === Region.Switzerland
    );
  }

  return (
    <ul
      className={styles.list}
      onMouseLeave={() => setLearnButtonActive(false)}
      onMouseEnter={() => {
        setShowLearnNavigation(true);
        setLearnButtonActive(true);
      }}
    >
      <li>
        <NavLink
          to="/book/expedition"
          end
          className={({ isActive }) =>
            clsx(styles.link, {
              [styles.linkActive]: isActive,
            })
          }
          onClick={() => {
            trackBook();
            setShowLearnNavigation(false);
          }}
          onMouseEnter={() => setShowLearnNavigation(false)}
        >
          <ButtonText>{translate('SearchBar_Tabs_Expedition')}</ButtonText>
        </NavLink>
      </li>
      {
        <li>
          <NavLink
            to="/learn"
            end
            className={({ isActive }) =>
              clsx(styles.link, {
                [styles.linkActive]: isActive,
              })
            }
            onClick={() => {
              trackLearn();
              setShowLearnNavigation(false);
            }}
          >
            <ButtonText>{translate('Navbar_Learn')}</ButtonText>
          </NavLink>
        </li>
      }
      {/* {canRegionShowTraining() && (
      {canRegionShowTraining() && (
        <li>
          <NavLink
            to="/training"
            end
            className={({ isActive }) =>
              clsx(styles.link, {
                [styles.linkActive]: isActive,
              })
            }
            onClick={() => {
              trackGetCertified();
              setShowLearnNavigation(false);
            }}
            onMouseEnter={() => setShowLearnNavigation(false)}
          >
            <ButtonText>{translate('Navbar_Training')}</ButtonText>
          </NavLink>
        </li>
      )} */}
      {canRegionShowTraining() && (
        <li>
          <NavLink
            to="learn/article/training-hx"
            end
            className={({ isActive }) =>
              clsx(styles.link, {
                [styles.linkActive]: isActive,
              })
            }
            onClick={() => {
              trackAgencyArea();
              setShowLearnNavigation(false);
            }}
          >
            <ButtonText>{translate('Navbar_Training')}</ButtonText>
          </NavLink>
        </li>
      )}
      {
        <li>
          <NavLink
            to="/marketing"
            end
            className={({ isActive }) =>
              clsx(styles.link, {
                [styles.linkActive]: isActive,
              })
            }
            onClick={() => {
              trackResources();
              setShowLearnNavigation(false);
            }}
            onMouseEnter={() => setShowLearnNavigation(false)}
          >
            <ButtonText>{translate('Navbar_Marketing')}</ButtonText>
          </NavLink>
        </li>
      }
      {marketId === 'DE' && (
        <li>
          <NavLink
            to="/learn/articleContainer/48WIPkoHRvQi0Fo6vhzdGa"
            end
            className={({ isActive }) =>
              clsx(styles.link, {
                [styles.linkActive]: isActive,
              })
            }
            onClick={() => {
              trackAgencyArea();
              setShowLearnNavigation(false);
            }}
          >
            <ButtonText>{translate('Navbar_AgencyArea')}</ButtonText>
          </NavLink>
        </li>
      )}
    </ul>
  );
};

export default NavItemsLeft;
