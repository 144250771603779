import { AxiosError } from 'axios';
import {
  IBookingDetailsData,
  ITravelDocumentsPayload,
} from 'interfaces/IBookingDetails';
import { Action, ActionFunctionAny, createActions } from 'redux-actions';
import axios from '../../axios-instance';
import { PRODUCT_TYPE } from '../../global-constants';

export const {
  fetchBookingDetailsStart,
  fetchBookingDetailsSuccess,
  fetchBookingDetailsFailed,
  resetBookingDetails,
  sendTravelDocumentsStart,
  sendTravelDocumentsSuccess,
  sendTravelDocumentsFailed,
} = createActions({
  FETCH_BOOKING_DETAILS_START: undefined,
  FETCH_BOOKING_DETAILS_SUCCESS: (data) => ({ data }),
  FETCH_BOOKING_DETAILS_FAILED: (error) => ({ error }),
  RESET_BOOKING_DETAILS: undefined,
  SEND_TRAVEL_DOCUMENTS_START: undefined,
  SEND_TRAVEL_DOCUMENTS_SUCCESS: undefined,
  SEND_TRAVEL_DOCUMENTS_FAILED: (error) => ({ error }),
});

export const fetchBookingDetails =
  (bookingId: string, isAgencyBooking: boolean) =>
  (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(fetchBookingDetailsStart());
    const subPath = isAgencyBooking ? 'detail' : 'agent';
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}Bookings/${subPath}/${bookingId}`
      )
      .then((res) => {
        dispatch(fetchBookingDetailsSuccess(res.data));
      })
      .catch((err) => {
        dispatch(fetchBookingDetailsFailed(err));
      });
  };

export const setBooking =
  (bookingDetails: IBookingDetailsData) =>
  (dispatch: ActionFunctionAny<Action<AxiosError>>) => {
    dispatch(fetchBookingDetailsSuccess(bookingDetails));
  };

export const sendTravelDocumnets =
  (data: ITravelDocumentsPayload) =>
  (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(sendTravelDocumentsStart());
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}Bookings/send-travel-docs`,
        data,
        {
          params: {
            productType: PRODUCT_TYPE,
          },
        }
      )
      .then(() => {
        dispatch(sendTravelDocumentsSuccess());
      })
      .catch((err) => {
        dispatch(sendTravelDocumentsFailed(err));
      });
  };
