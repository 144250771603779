import { ILoginErrorMessage } from 'interfaces/ILoginErrorMessage';
import { api } from './api';

export const loginErrorApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchLoginError: build.query<ILoginErrorMessage, void>({
      query: () => ({
        url: `loginError`,
      }),
    }),
  }),
});

export const { useFetchLoginErrorQuery } = loginErrorApi;

export const {
  endpoints: { fetchLoginError },
} = loginErrorApi;
