import { ReactNode, useState } from 'react';
import clsx from 'clsx';
import {
  BodyText,
  InfoText,
  Overline,
  TagText,
} from 'components/UI/Brand/text';
import Icon from 'components/UI/Icons/Icon';
import { colors } from 'style/colors';
import styles from './styles.module.scss';

export const DetailsDisclosure = ({
  buttonText,
  title,
  bodyText,
  isOpen = false,
  hasButton = true,
  isRegularTitle = false,
  isCustomStructure,
}: {
  bodyText: ReactNode;
  title?: string;
  buttonText?: string;
  isOpen?: boolean;
  hasButton?: boolean;
  isRegularTitle?: boolean;
  isCustomStructure?: boolean;
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(isOpen);

  return (
    <details>
      <summary
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className={clsx(styles.summary, {
          [styles.classNameSummary]: isCustomStructure,
          [styles.hasTwoColumns]: Boolean(title && hasButton),
        })}
      >
        {!!title && isRegularTitle ? (
          <InfoText>{title}</InfoText>
        ) : (
          <Overline className={styles.title}>{title}</Overline>
        )}
        {!!hasButton && (
          <span className={styles.viewButton}>
            {!!buttonText && (
              <TagText className={styles.titleIcon}>{buttonText}</TagText>
            )}
            <Icon
              iconType={isDropdownOpen ? 'arrowUp' : 'arrowDown'}
              width={12}
              height={12}
              color={colors.shared.black}
            />
          </span>
        )}
      </summary>
      {isCustomStructure ? (
        <>{bodyText}</>
      ) : (
        <BodyText className={styles.textRows}>{bodyText}</BodyText>
      )}
    </details>
  );
};
