import { useState, useEffect } from 'react';
import { Header2, BodyText, Label, HelperText } from 'components/UI/Brand/text';
import { useSelector } from 'react-redux';
import Axios from 'axios';
import inputValidator from 'utils/inputValidator';
import * as actions from 'store/actions';
import translate from 'translate';
import { IRootState } from 'store/types/root';
import debounce from 'utils/debounce';
import { RoundButtonLoadable } from 'components/UI/Buttons/RoundButtonLoadable/RoundButtonLoadable';
import { useAppDispatch } from 'hooks/storeHooks';
import { useNavigate } from 'react-router-dom';
import { DEBOUNCE_TIME } from 'utils/constants';
import { IEmailValidation } from 'interfaces/IEmailValidation';
import { TextInput } from 'components';
import { TextInputChangeEvent } from 'components/UI/TextInput/TextInput';
import LoginHelpBlock from '../LoginHelpBlock/LoginHelpBlock';
import styles from './styles.module.scss';
import { PRODUCT_TYPE } from '../../../global-constants';

const validation = debounce(
  (
    name: string,
    value: string,
    setEmailValidation: (value: IEmailValidation) => void,
    emailValidation: IEmailValidation
  ) => {
    setEmailValidation({
      ...emailValidation,
      valid: inputValidator(name, value),
      touched: true,
    });
  },
  DEBOUNCE_TIME
);

interface EmailVerificationProps {
  title: string;
  desc: string;
  onSubmit: (email: string) => void;
}

const EmailVerification = ({
  title,
  desc,
  onSubmit,
}: EmailVerificationProps) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [emailValidation, setEmailValidation] = useState<IEmailValidation>({
    valid: false,
    touched: false,
    errorMessage: translate('SignUp_EmailVerification_EmailError'),
  });
  const [loading, setLoading] = useState(false);
  const [emailIsExistAuth0, setEmailIsExistAuth0] = useState(null);
  const [emailIsExistSalesforce, setEmailIsExistSalesforce] = useState(null);
  const dispatch = useAppDispatch();

  const featureFlags = useSelector((state: IRootState) => state.feature?.flags);

  const onInputChanged = (event: TextInputChangeEvent) => {
    const { value, name } = event.target;
    setEmailValidation({ ...emailValidation, touched: true });
    setEmail(value);
    validation(name, value, setEmailValidation, emailValidation);
  };

  const fetchEmailApi = async () => {
    try {
      setLoading(true);
      const response = await Axios.post(
        `${process.env.REACT_APP_API_BASE_URL}user/validate`,
        { email },
        { params: { productType: PRODUCT_TYPE } }
      );
      setEmailIsExistAuth0(response.data.auth0);
      setEmailIsExistSalesforce(response.data.salesforce);
      setLoading(false);
    } catch (error) {
      let errorMessage = translate(
        'SignUp_EmailVerification_SomethingWentWrongWhenEmailWasValidated'
      );

      if (
        Axios.isAxiosError(error) &&
        error.response &&
        featureFlags.DeletedAccountFeedback
      ) {
        const { errorCode } = error.response.data;
        if (errorCode === '400O') {
          errorMessage = translate(
            'SignUp_EmailVerification_SomethingWentWrongWhenEmailWasValidatedWithEmail'
          );
        }
      }

      dispatch(actions.createFailedMessage(errorMessage));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (emailIsExistAuth0 === false && emailIsExistSalesforce === false) {
      onSubmit(email);
    }
    if (emailIsExistAuth0 === false && emailIsExistSalesforce === true) {
      navigate(`/accountmigration`, { state: email });
    }
    if (emailIsExistAuth0 === true && emailIsExistSalesforce === true) {
      setEmailValidation({
        valid: false,
        touched: emailValidation.touched,
        errorMessage: translate('SignUp_EmailVerification_EmailError'),
      });
      setEmailIsExistAuth0(null);
      setEmailIsExistSalesforce(null);
    }
    if (emailIsExistAuth0 === true && emailIsExistSalesforce === false) {
      navigate('/login');
    }
  }, [emailIsExistSalesforce, emailIsExistAuth0]);

  return (
    <div className={styles.emailVerification}>
      <Header2 marginBottom="five">{title}</Header2>
      <BodyText className={styles.desc}>{desc}</BodyText>
      <div className={styles.mainCntr}>
        <div className={styles.leftCntr}>
          <form
            className={styles.formContainer}
            onSubmit={(event) => {
              if (!emailValidation.valid || loading || emailValidation.touched)
                event.preventDefault();
              fetchEmailApi();
            }}
          >
            <Label htmlFor="onboard_emailverification_input">
              {translate('SignUp_EmailVerification_EmailLabel')}
            </Label>
            <div className={styles.inputContainer}>
              <TextInput
                className={styles.inputField}
                id="onboard_emailverification_input"
                name="email"
                placeholder={translate(
                  'SignUp_EmailVerification_EmailPlaceholder'
                )}
                value={email}
                onChange={onInputChanged}
                isAutoFocus
                error={!emailValidation.valid && emailValidation.touched}
              />
              <HelperText
                hasError={!emailValidation.valid}
                data-testid="email-input-helper-text"
              >
                {!emailValidation.valid && emailValidation.touched
                  ? emailValidation.errorMessage
                  : null}
              </HelperText>
            </div>
            <div className={styles.buttonCntr}>
              <RoundButtonLoadable
                id="onboard_emailverification_submit_btn"
                disabled={!emailValidation.valid || loading}
                onClick={fetchEmailApi}
                type="submit"
              >
                {translate('General_Continue')}
              </RoundButtonLoadable>
            </div>
          </form>
        </div>
        <div className={styles.rightCntr}>
          <LoginHelpBlock
            title={translate('SignUp_EmailVerification_AlreadyHaveAnAccount')}
            desc={translate(
              'SignUp_EmailVerification_IfYouHaveAlreadyAccountPleaseLogin'
            )}
            buttonText={translate('SignUp_EmailVerification_LoginButton')}
            onClick={() => {
              navigate('/login');
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
