import { createActions, ActionFunctionAny, Action } from 'redux-actions';
import { IContactInfo, ILandingPage } from 'interfaces/IHome';
import { AxiosError } from 'axios';
import { storeSupportInfo } from '../../utils/supportInfo';
import axios from '../../axios-instance';
import { PRODUCT_TYPE } from '../../global-constants';

export const {
  fetchLandingPageStart,
  fetchLandingPageSuccess,
  fetchLandingPageFailed,
  setFooterData,
  fetchContactDataFailed,
} = createActions({
  FETCH_LANDING_PAGE_START: (publicIp: string) => ({ publicIp }),
  FETCH_LANDING_PAGE_SUCCESS: (landingPageData: ILandingPage) => ({
    landingPageData,
  }),
  FETCH_LANDING_PAGE_FAILED: (error: AxiosError) => ({ error }),
  SET_FOOTER_DATA: (footerData: IContactInfo) => ({ footerData }),
  FETCH_CONTACT_DATA_FAILED: (error: AxiosError) => ({ error }),
});

export const fetchLandingPageData =
  (ip: string) => (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(fetchLandingPageStart(ip));

    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}LandingPage`, {
        headers: {
          'x-fallback-ip': ip,
        },
        params: {
          productType: PRODUCT_TYPE,
        },
      })
      .then((res) => {
        dispatch(fetchLandingPageSuccess(res.data));
      })
      .catch((err: AxiosError) => {
        dispatch(fetchLandingPageFailed(err));
      });
  };

export const onFooterData =
  (footerData: IContactInfo) => (dispatch: ActionFunctionAny<Action<void>>) => {
    const { market, email, phone, mailingAddress, locale } = footerData;

    storeSupportInfo({ market, email, phone, mailingAddress, locale });

    dispatch(setFooterData(footerData));
  };

export const fetchContactData =
  (market: string) => (dispatch: ActionFunctionAny<Action<void>>) => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}contact/${market}`)
      .then((res) => {
        dispatch(onFooterData(res.data));
      })
      .catch((err: AxiosError) => {
        dispatch(fetchContactDataFailed(err));
      });
  };
