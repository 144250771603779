import { ButtonText } from 'components/UI/Brand/text';
import { track } from 'utils/analytics';
import clsx from 'clsx';
import styles from './styles.module.scss';

interface IHeaderItem {
  name: string;
  id: number;
}

interface ITabHeaderProps {
  headerItems: IHeaderItem[];
  activeTabIdx: number;
  setActiveTabIdx: (index: number) => void;
  desktopContainer?: boolean;
  destinationName?: string;
}

const TabHeader = (props: ITabHeaderProps) => {
  const {
    headerItems,
    setActiveTabIdx,
    activeTabIdx,
    desktopContainer = false,
    destinationName,
  } = props;
  return (
    <div
      className={[
        desktopContainer ? styles.headerContainer : styles.tabHeaderContainer,
        headerItems && headerItems.length > 2 ? styles.scrollX : null,
      ]
        .filter((_class) => _class)
        .join(' ')}
    >
      <div className={styles.headerContentContainer}>
        {headerItems.map((headerItem, idx) => (
          <button
            type="button"
            role="tab"
            key={headerItem.name}
            tabIndex={idx}
            className={clsx(
              idx === activeTabIdx
                ? styles.tabHeaderItemActive
                : styles.tabHeaderItem
            )}
            onClick={() => {
              track(
                `Click ${headerItem.name} highlights`,
                destinationName ? { Destination: destinationName } : null
              );
              setActiveTabIdx(idx);
            }}
          >
            <ButtonText>{headerItem.name}</ButtonText>
          </button>
        ))}
      </div>
    </div>
  );
};

export default TabHeader;
