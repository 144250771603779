import { Suspense, lazy } from 'react';
import { Routes, Navigate, Outlet, Route, useLocation } from 'react-router-dom';
import { WHITE_LIST_LINKS } from 'utils/constants';
import { hasValidToken } from '../utils/getToken';
import Loading from '../components/UI/Loading/LoadingComponent';

const ArticleContainerPage = lazy(
  () => import('../containers/Learn/Articles/ArticleContainerPage')
);
const ArticleDetail = lazy(
  () => import('../containers/Learn/Articles/ArticleDetail')
);
const Book = lazy(() => import('../containers/Book/Book'));
const BookingProgress = lazy(
  () => import('../containers/Book/BookingProgress/BookingProgress')
);

const Bookings = lazy(
  () => import('../containers/Bookings/BookingsList/Bookings')
);
const BookingPayment = lazy(
  () =>
    import(
      '../containers/Book/BookingProgress/BookingFunnel/Payment/BookingPayment'
    )
);
const CampaignPage = lazy(
  () => import('../containers/Learn/CampaignPage/CampaignPage')
);
const Confirm = lazy(() => import('../containers/Onboard/Confirm'));
const ConfirmEmailRegistration = lazy(
  () => import('../containers/Onboard/Screens/ConfirmEmailRegistration')
);
const DestinationDetail = lazy(
  () => import('../containers/Learn/Destination/DestinationDetail')
);
const ChangePassword = lazy(
  () => import('../containers/MyProfile/Edit/ChangePassword')
);
const EditAgencyInfo = lazy(
  () => import('../containers/MyProfile/Edit/EditAgencyInfo')
);
const EditProfile = lazy(
  () => import('../containers/MyProfile/Edit/EditProfile')
);
const EmailChanged = lazy(
  () => import('../containers/MyProfile/EmailChangedPage')
);
const EmailVerified = lazy(() => import('../containers/Onboard/EmailVerified'));
const ForgotPassword = lazy(
  () => import('../containers/Onboard/ForgotPassword')
);
const LandingPage = lazy(
  () => import('../containers/LandingPage/LandingPage/LandingPage')
);
const Learn = lazy(() => import('../containers/Learn/Learn'));
const Login = lazy(() => import('../containers/Login/Login'));
const Logout = lazy(() => import('../components/Logout/Logout'));
const Marketing = lazy(() => import('../containers/Marketing/Marketing'));
const MyBookingsDetails = lazy(
  () =>
    import('../containers/Bookings/Details/MyBookingsDetails/MyBookingsDetails')
);
const MyProfile = lazy(() => import('../containers/MyProfile/MyProfile'));
const News = lazy(() => import('../containers/News/News'));
const PromotionCollectionPage = lazy(
  () => import('containers/Promotions/PromotionCollectionPage')
);
const PromotionPage = lazy(() => import('containers/Promotions/PromotionPage'));
const ResetPassword = lazy(
  () => import('../containers/Onboard/ResetPassword/ResetPassword')
);
const ShipPageWrapper = lazy(
  () => import('../containers/Learn/Ship/ShipPageWrapper')
);
const SignUpFifthPage = lazy(
  () => import('../containers/Onboard/Screens/SignUpFifthPage')
);
const SignUpFirstPage = lazy(
  () => import('../containers/Onboard/Screens/SignUpFirstPage')
);
const SignUpFourthPage = lazy(
  () => import('../containers/Onboard/Screens/SignUpFourthPage')
);
const SignUpSecondPage = lazy(
  () => import('../containers/Onboard/Screens/SignUpSecondPage')
);
const SignUpThirdPage = lazy(
  () => import('../containers/Onboard/Screens/SignUpThirdPage')
);
const TokenError = lazy(() => import('../containers/Onboard/TokenError'));
const TourDetail = lazy(() => import('containers/Tour/TourPage'));
// const Training = lazy(() => import('../containers/Training/Training'));
// const TrainingCompleted = lazy(
//   () => import('../containers/Training/TrainingCompleted')
// );
// const TrainingModulesPage = lazy(
//   () => import('../containers/Training/TrainingModules/TrainingModulesPage')
// );
const NotFound = lazy(() => import('containers/Errors/NotFound'));

const beforeAuthPaths = [
  'logout',
  'onboard',
  'confirm',
  'tokenerror',
  'signupfinish',
  'forgetpassword',
  'passwordreset',
  'login',
  'emailconfirmation',
  'accountmigration',
  '/',
];

function RequireAuth({ redirectTo }: { redirectTo: string }) {
  const location = useLocation();

  const isFooterLinks = WHITE_LIST_LINKS.includes(location?.pathname);

  const isAuthenticated = hasValidToken();
  const basedPath =
    location.pathname !== '/' ? location.pathname.split('/')?.[1] : '/';

  if (isFooterLinks) {
    return <Outlet />;
  }

  if (!isAuthenticated && !beforeAuthPaths.includes(basedPath)) {
    return <Navigate to={'/login'} />;
  }

  if (isAuthenticated && beforeAuthPaths.includes(basedPath)) {
    return <Navigate to={'/book/expedition'} />;
  }

  if (!isAuthenticated && beforeAuthPaths.includes(basedPath)) {
    return <Outlet />;
  }

  return isAuthenticated ? <Outlet /> : <Navigate to={redirectTo} replace />;
}

export const RenderRoutes = () => (
  <Suspense fallback={<Loading />}>
    <Routes>
      <Route path="/" element={<RequireAuth redirectTo="/login" />}>
        <Route index element={<LandingPage />} />
      </Route>

      <Route path="/login" element={<RequireAuth redirectTo="/" />}>
        <Route index element={<Login />} />
        <Route path="/login/:token" element={<Login />} />
      </Route>

      <Route
        path="/accountmigration"
        element={<RequireAuth redirectTo="/login" />}
      >
        <Route index element={<Login />} />
        <Route path="/accountmigration" element={<Login />} />
      </Route>

      <Route
        path="/emailconfirmation/:email"
        element={<RequireAuth redirectTo="/login" />}
      >
        <Route index element={<ConfirmEmailRegistration />} />
      </Route>

      <Route
        path="/passwordreset/:token"
        element={<RequireAuth redirectTo="/login" />}
      >
        <Route index element={<ResetPassword />} />
      </Route>

      <Route
        path="/forgetpassword"
        element={<RequireAuth redirectTo="/login" />}
      >
        <Route index element={<ForgotPassword />} />
        <Route
          path="/forgetpassword/:emailParam"
          element={<ForgotPassword />}
        />
      </Route>

      <Route path="/signupfinish" element={<RequireAuth redirectTo="/login" />}>
        <Route index element={<EmailVerified />} />
      </Route>

      <Route
        path="/book/:productType"
        element={<RequireAuth redirectTo="/login" />}
      >
        <Route index element={<Book />} />
      </Route>

      <Route
        path="/book/progress"
        element={<RequireAuth redirectTo="/login" />}
      >
        <Route
          path=":step/:cabinIndex/:quoteId"
          element={<BookingProgress />}
        />
        <Route path=":step/:quoteId" element={<BookingProgress />} />
        <Route path=":step" element={<BookingProgress />} />
      </Route>

      <Route path="/learn" element={<RequireAuth redirectTo="/login" />}>
        <Route index element={<Learn />} />
        <Route path="/learn/:category" element={<Learn />} />
        <Route path="/learn/destination/:id" element={<DestinationDetail />} />
        <Route
          path="/learn/articleContainer/:id"
          element={<ArticleContainerPage />}
        />
        <Route path="/learn/article/:id" element={<ArticleDetail />} />
        <Route path="/learn/ship/:code" element={<ShipPageWrapper />} />
        <Route path="/learn/tours/:id" element={<TourDetail />} />
        <Route path="/learn/campaign/:name" element={<CampaignPage />} />
      </Route>

      <Route path="/myprofile" element={<RequireAuth redirectTo="/login" />}>
        <Route index element={<MyProfile />} />
        <Route path="/myprofile/editagency" element={<EditAgencyInfo />} />
        <Route path="/myprofile/editprofile" element={<EditProfile />} />
        <Route path="/myprofile/changepassword" element={<ChangePassword />} />
      </Route>

      {/* <Route path="/training" element={<RequireAuth redirectTo="/login" />}>
        <Route index element={<Training />} />
        <Route path="/training/module/:id" element={<TrainingModulesPage />} />
        <Route
          path="/training/module/:id/completed"
          element={<TrainingCompleted />}
        />
        <Route path="/training/completed" element={<TrainingCompleted />} />
      </Route> */}

      <Route path="/onboard" element={<RequireAuth redirectTo="/onboard" />}>
        <Route index element={<SignUpFirstPage />} />
        <Route path="/onboard/password" element={<SignUpSecondPage />} />
        <Route path="/onboard/region" element={<SignUpThirdPage />} />
        <Route path="/onboard/agency" element={<SignUpFourthPage />} />
        <Route path="/onboard/agent" element={<SignUpFifthPage />} />
        <Route path="/onboard/finish" element={<ConfirmEmailRegistration />} />
      </Route>

      <Route path="/promotions" element={<RequireAuth redirectTo="/login" />}>
        <Route index element={<PromotionCollectionPage />} />
        <Route
          path="/promotions/expedition"
          element={<PromotionCollectionPage />}
        />
        <Route
          path="/promotions/coastal"
          element={<PromotionCollectionPage />}
        />
        <Route path="/promotions/:id" element={<PromotionPage />} />
      </Route>

      <Route path="/payment/:id" element={<RequireAuth redirectTo="/login" />}>
        <Route index element={<BookingPayment />} />
      </Route>

      <Route path="/bookings" element={<RequireAuth redirectTo="/login" />}>
        <Route index element={<Bookings />} />
        <Route path="/bookings/:id" element={<MyBookingsDetails />} />
      </Route>

      <Route path="/marketing" element={<RequireAuth redirectTo="/login" />}>
        <Route index element={<Marketing />} />
      </Route>

      <Route path="/news/:brand" element={<RequireAuth redirectTo="/login" />}>
        <Route index element={<News />} />
      </Route>

      <Route path="/logout" element={<RequireAuth redirectTo="/login" />}>
        <Route index element={<Logout />} />
      </Route>

      <Route
        path="/confirm/:token"
        element={<RequireAuth redirectTo="/login" />}
      >
        <Route index element={<Confirm />} />
      </Route>

      <Route
        path="/tokenerror/:email"
        element={<RequireAuth redirectTo="/login" />}
      >
        <Route index element={<TokenError />} />
      </Route>

      <Route path="/emailchanged" element={<RequireAuth redirectTo="/login" />}>
        <Route index element={<EmailChanged />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  </Suspense>
);
