import { INewBookingPassenger, IPayment } from 'interfaces/INewBooking';
import { useNavigate } from 'react-router-dom';
import { getLanguage } from 'utils/getLanguage';
import { getLastTransactionDate } from 'utils/getPayDate';

import { getDaysLeftForNewBooking } from 'containers/Bookings/BookingsList/getDaysLeft';
import { BodyText, InfoText } from 'components/UI/Brand/text';
import clsx from 'clsx';
import styles from './styles.module.scss';
import { getStatusTextInfo } from './utils/getStatusTextInfo';
import { getRenderStatusInfo } from './utils/getRenderStatusInfo';

interface IBookingCard {
  isCreditAgency: boolean;
  balance: number;
  totalPaymentsReceived: number;
  title: string;
  passengers: INewBookingPassenger[];
  status: string;
  depositAmount: number;
  departureDate: string;
  depositDueDate: string;
  finalDueDate: string;
  payments: IPayment[];
  bookingId: number;
}

export const BookingCard = ({
  balance,
  totalPaymentsReceived,
  title,
  passengers,
  status,
  depositAmount,
  departureDate,
  depositDueDate,
  finalDueDate,
  payments,
  isCreditAgency,
  bookingId,
}: IBookingCard) => {
  const navigate = useNavigate();
  const language = getLanguage();
  const daysLeft = getDaysLeftForNewBooking({
    depositAmount,
    totalPaymentsReceived,
    balance,
    depositDueDate,
    finalDueDate,
  });

  const payDate = getLastTransactionDate(payments);

  const renderStatus = () => {
    const { statusBgColor, statusText, infoTextColor, deadlineMessage } =
      getRenderStatusInfo({
        balance,
        departureDate,
        depositAmount,
        status,
        totalPaymentsReceived,
        isCreditAgency,
        daysLeft,
        payDate,
        language,
      });

    return (
      <>
        <InfoText
          className={styles.status}
          style={{ backgroundColor: statusBgColor }}
        >
          {statusText}
        </InfoText>
        {!statusText && (
          <InfoText marginTop="one" style={{ color: infoTextColor }}>
            {deadlineMessage}
          </InfoText>
        )}
      </>
    );
  };

  const renderStatusText = () => {
    const { textColor, statusText } = getStatusTextInfo({
      depositAmount,
      totalPaymentsReceived,
      balance,
      status,
      departureDate,
      daysLeft,
      payDate,
      language,
    });

    return (
      <div className={styles.statusText} style={{ color: textColor }}>
        {statusText}
      </div>
    );
  };

  const renderContent = () => (
    <div className={styles.contentRoot}>
      {renderStatus()}
      <div className={styles.text}>
        <span style={{ fontWeight: 800 }}>
          {passengers?.length ? passengers[0].name : ''}
        </span>
        <BodyText>{title}</BodyText>
      </div>
      {!isCreditAgency && renderStatusText()}
    </div>
  );

  return (
    <div
      className={clsx({
        [styles.root]: status === 'Cancelled',
        [styles.clickableRoot]: status !== 'Cancelled',
      })}
      onClick={() => {
        if (status === 'Cancelled') {
          return;
        }
        navigate(`/bookings/${bookingId}`);
      }}
    >
      {renderContent()}
    </div>
  );
};
